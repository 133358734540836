<template>
  <octo-table
    show-filters
    :show-filters-collapse="false"
    :action="endpoints.DATATABLES.tutors"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="resetFilters"
  >
    <template v-slot:tutor="data">
      {{ data.row | optional('lastname') }} {{ data.row | optional('firstname') }}
      <div class="small">{{ data.row.username }}</div>
    </template>

    <template v-slot:classrooms="data">
      {{ data.row.classrooms.length }}
    </template>

    <template v-slot:courses="data">
      {{ getCourseString(data.row.courses) }}
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <base-button
          link
          icon
          class="mx-1"
          size="sm"
          @click="$router.push({name: 'users.tutors.show', params: {id: data.row.id}})"
        >
          <octo-icon icon="right-arrow"/>
        </base-button>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import {mapGetters} from "vuex";

export default {
  name: "TutorDatatable",
  components: {OctoIcon, OctoTable},
  data() {
    return {
      name: 'TutorDatatable',
      endpoints: endpoints,
      filters: {
        roles: ['tutor']
      },
      fields: [
        {prop: 'tutor', label: 'tutor', slot: true, minWidth: 200},
        {prop: 'classrooms', label: 'classrooms', slot: true, align: 'center'},
        {prop: 'courses', label: 'courses', slot: true, align: 'left'},
        {prop: 'actions', label: 'actions', width: 100, slot: true},
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
    }),
  },
  methods: {
    resetFilters: function () {
      this.filters = {
        roles: ['tutor']
      };
    },

    getCourseString(courses) {
      return this.$_.join(this.$_.map(courses, course => course.code), ', ');
    }
  }
}
</script>

<style scoped>

</style>
