<template>
  <div class="row">
    <div class="col-12">
      <tutor-datatable/>
    </div>
  </div>
</template>

<script>
  import TutorDatatable from "./datatables/TutorDatatable";

  export default {
    name: "IndexTutorPage",
    components: {TutorDatatable},
  }
</script>

<style scoped>

</style>
